import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FaPizzaSlice } from 'react-icons/fa';
import { createRoot } from 'react-dom/client';
import { useBranch } from '../context/BranchContext.js';


mapboxgl.accessToken = 'pk.eyJ1IjoiZGljb3J1cyIsImEiOiJjbHcxZ2ZtOGswNWl3MmlvMGIyYnEzczlrIn0.4R-1dS1WNVwHGEpPYoP-5g';

const locations = {
  'Centro': {
    center: [-65.20480901380695, -26.830798648569647],
    iconSize: [60, 60],
    popupContent: 'La Pizzada Centro: 24 DE SEPTIEMBRE 51, Tucumán',
    mapUrl: 'https://www.google.com/maps/place/La+Pizzada+Centro/@-26.8306567,-65.205879,19z/data=!4m10!1m2!2m1!1sLa+Pizzada!3m6!1s0x94225c1045c01da1:0x4ff0bd25ec395c81!8m2!3d-26.8306567!4d-65.2047525!15sCgpMYSBQaXp6YWRhWgwiCmxhIHBpenphZGGSARBwaXp6YV9yZXN0YXVyYW504AEA!16s%2Fg%2F11bbrmn8rt?hl=es&entry=ttu'
  },
  'Barrio Norte': {
    center: [-65.21074989396648, -26.822629123942246],
    iconSize: [60, 60],
    popupContent: 'La Pizzada Barrio Norte: SANTIAGO DEL ESTERO 1002, Tucumán',
    mapUrl: 'https://www.google.com/maps/place/La+Pizzada+Barrio+Norte/@-26.8226343,-65.2119332,19z/data=!4m10!1m2!2m1!1sLa+Pizzada!3m6!1s0x94225c3e278a1b3d:0x204070a021c3a908!8m2!3d-26.8226343!4d-65.2108067!15sCgpMYSBQaXp6YWRhWgwiCmxhIHBpenphZGGSARBwaXp6YV9yZXN0YXVyYW504AEA!16s%2Fg%2F11bc7twhh5?hl=es&entry=ttu'
  },
};

const BranchMap = () => {
  const { selectedBranch, setSelectedBranch } = useBranch();
  const mapContainerRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/dicorus/clw1gz2gu08az01ph59c56j05',
      center: locations['Centro'].center,
      zoom: 14
    });

    addMarkers(mapRef.current);

    return () => mapRef.current.remove();
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    const { center } = locations[selectedBranch];
    mapRef.current.flyTo({ center, zoom: 16 });
    localStorage.setItem('selectedBranch', selectedBranch);

  }, [selectedBranch,mapRef.current]);

  // Función para añadir todos los marcadores
  const addMarkers = (map) => {
    Object.keys(locations).forEach(branch => {
      const { center, iconSize, popupContent, mapUrl } = locations[branch];
      const el = document.createElement('div');
      const width = iconSize[0];
      const height = iconSize[1];
      el.className = 'marker';
      el.style.width = `${width}px`;
      el.style.height = `${height}px`;
      el.style.display = 'flex';
      el.style.alignItems = 'center';
      el.style.justifyContent = 'center';
      el.style.border = 'none';
      el.style.borderRadius = '50%';
      el.style.cursor = 'pointer';
      el.style.padding = 0;

      // Renderizar el icono dentro del marcador usando createRoot
      const root = createRoot(el);
      root.render(<FaPizzaSlice className="text-yellow-600" size={30} />);

      const marker = new mapboxgl.Marker(el)
        .setLngLat(center)
        .addTo(map);

      // Configurar el popup
      const popup = new mapboxgl.Popup({ offset: 30 })
        .setHTML(`
          <h3>${popupContent}</h3>
          <a href="${mapUrl}" target="_blank" class="text-blue-500 hover:underline">Ir</a>
        `)
        .setMaxWidth('300px');

      marker.setPopup(popup);
    });
  };

  return (
    <div id="branch-selection"  className="relative flex flex-col items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat py-12"
      style={{ backgroundImage: "url('/2.jpg')", backgroundAttachment: 'fixed' }}>
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
      <div className="relative z-20 container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-white mb-10">Selecciona tu Sucursal</h2>
        
        {/* Nueva sección para mostrar la sucursal seleccionada */}
        <div className="mb-8">
          <h3 className="text-2xl font-semibold text-yellow-300">Sucursal Actual</h3>
          <p className="text-lg text-white">{selectedBranch}</p>
        </div>
        
        <div className="flex justify-center mb-10">
          <button
            className={`px-6 py-2 text-lg font-semibold ${selectedBranch === 'Centro' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-800'} rounded-l-lg border border-gray-300`}
            onClick={() => setSelectedBranch('Centro')}
          >
            La Pizzada Centro
          </button>
          <button
            className={`px-6 py-2 text-lg font-semibold ${selectedBranch === 'Barrio Norte' ? 'bg-yellow-500 text-white' : 'bg-white text-gray-800'} rounded-r-lg border border-gray-300`}
            onClick={() => setSelectedBranch('Barrio Norte')}
          >
            La Pizzada Barrio Norte
          </button>
        </div>
        
        <div ref={mapContainerRef} id="map" style={{ height: '400px', width: '100%' }} />
      </div>
    </div>
  );
};

export default BranchMap;
