import React, { useState } from 'react';
import BranchMap from './branchMap';

const BranchSelection = () => {


  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: "url('/2.jpg')",
        backgroundAttachment: 'fixed'
      }}>
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
      <div className="relative z-20 container mx-auto px-4 text-center">

        <BranchMap />

      </div>
    </div>
  );
}

export default BranchSelection;
