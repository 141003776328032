import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const PizzaRotationSection = () => {
  const pizzaRef1 = useRef(null);
  const pizzaRef2 = useRef(null);
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);

  useEffect(() => {
    const animatePizza = (pizzaElement) => {
      gsap.fromTo(
        pizzaElement,
        { rotation: 0 },
        {
          rotation: 360,
          duration: 3,
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: pizzaElement,
            start: 'top 80%',
            once: true,
          },
        }
      );
    };

    const animateText = (textElement) => {
      gsap.fromTo(
        textElement,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: textElement,
            start: 'top 80%',
            once: true,
          },
        }
      );
    };

    animatePizza(pizzaRef1.current);
    animatePizza(pizzaRef2.current);
    animateText(textRef1.current);
    animateText(textRef2.current);
  }, []);

  return (
    <>
      {/* Primera Sección */}
      <section id='acercaDe' className="relative flex items-center justify-center min-h-[60vh] bg-black px-4 py-8">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 max-w-screen-xl mx-auto">
          <div ref={textRef1} className="text-white text-center md:text-left flex-1">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Nuestra Pizza Desde 1993</h2>
            <p className="text-lg md:text-xl leading-relaxed">
              Respetamos las mejores recetas y utilizamos los ingredientes de la más alta calidad,
              que en las manos de nuestros maestros pizzero se transforman en delicias que
              verdaderamente dejan huellas de sabor.
            </p>
          </div>
          <div
            ref={pizzaRef1}
            className="w-72 h-72 md:w-96 md:h-96"
            style={{
              backgroundImage: 'url(/pizza1.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '50%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            }}
          />
        </div>
      </section>

      {/* Segunda Sección */}
      <section className="relative flex items-center justify-center min-h-[60vh] bg-black px-4 py-8">
        <div className="flex flex-col md:flex-row-reverse items-center justify-between gap-8 max-w-screen-xl mx-auto">
          <div ref={textRef2} className="text-white text-center md:text-right flex-1">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Un Legado en Evolución</h2>
            <p className="text-lg md:text-xl leading-relaxed">
              A lo largo de los años, La Pizzada ha sabido interpretar la necesidad del cambio como fuerza generadora de progreso.
              Mejorando continuamente nuestros productos, calidad, y creando un estilo gráfico distintivo en todas nuestras piezas de comunicación.
              Desde 1993, hemos trabajado con una constante evolución visual, integrando promociones, mailing y merchandising de alta calidad.
              Siempre dispuestos a nuevos desafíos, hemos logrado mantener una relación visual coherente con nuestros clientes, apostando por una identidad corporativa sólida y memorable.
            </p>
          </div>
          <div
            ref={pizzaRef2}
            className="w-72 h-72 md:w-96 md:h-96"
            style={{
              backgroundImage: 'url(/pizza2.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: '50%',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
            }}
          />
        </div>
      </section>

    </>
  );
};

export default PizzaRotationSection;
