import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from './header';
import BranchSelectionPopup from './branchSelectionPopup.js';

const Hero = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [contactInfo, setContactInfo] = useState({});

    useEffect(() => {
        // Obtener sucursal seleccionada del localStorage
        const storedBranch = localStorage.getItem('selectedBranch');
        setSelectedBranch(storedBranch);

        // Cargar datos del archivo JSON
        fetch('/contactInfo.json')
            .then((response) => response.json())
            .then((data) => setContactInfo(data))
            .catch((error) => console.error('Error fetching contact info:', error));
    }, []);

    const handleOpenPopup = () => {
        setPopupOpen(true);
    };

    const onContinue = () => {
        if (selectedBranch && contactInfo[selectedBranch]) {
            const branch = contactInfo[selectedBranch];
            const phoneNumber = branch.phoneNumbers[0];
            const message = `Hola, me gustaría hacer una reserva para el dia: `;
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            console.error('No branch selected or contact info unavailable.');
        }
    };


    const handleChangeBranch = () => {
        // Desplaza al componente de BranchSelection
        const branchSelectionSection = document.getElementById('branch-selection');
        if (branchSelectionSection) {
            branchSelectionSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div
            className="relative min-h-screen bg-cover bg-center bg-no-repeat"
            style={{
                backgroundImage: "url('/ternera.jpg')",
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover'
            }}
        >
            {/* Overlay Oscuro */}
            <div className="absolute inset-0 bg-black opacity-50 z-0"></div>
            <Header className="" />

            {/* Contenedor del contenido */}
            <div className="relative z-10 flex flex-col items-center justify-center min-h-screen px-4 lg:px-8">
                {/* Contenido Principal */}
                <motion.img
                    src={'./logo.svg'}
                    alt="Pizza Logo"
                    className="w-2/3 md:w-1/2 lg:w-1/3 h-auto mb-8"
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 1, ease: 'easeOut' }}
                />
                <motion.h1
                    className="text-3xl md:text-4xl lg:text-6xl text-white text-center mb-8"
                    initial={{ y: -100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1.5, ease: 'easeOut' }}
                >
                    Desde 1993 la Mejor Pizza de Tucumán
                </motion.h1>
                <motion.button
                    className="px-6 py-3 bg-white text-black rounded-lg shadow-lg text-lg md:text-xl"
                    whileHover={{ scale: 1.1, y: -5 }}
                    whileTap={{ scale: 0.9, y: 2 }}
                    transition={{ duration: 0.2 }}
                    onClick={handleChangeBranch}
                >
                    Ver sucursales

                </motion.button>

                <BranchSelectionPopup isOpen={isPopupOpen} onContinue={onContinue} onClose={() => setPopupOpen(false)} />
            </div>
        </div>
    );
}

export default Hero;
