import React from 'react';
import { motion } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const handleChangeBranch = () => {
        // Desplaza al componente de BranchSelection
        const branchSelectionSection = document.getElementById('branch-selection');
        if (branchSelectionSection) {
            branchSelectionSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const goToContacto = () => {
        // Desplaza al componente de BranchSelection
        const branchSelectionSection = document.getElementById('footer');
        if (branchSelectionSection) {
            branchSelectionSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const goToAcercaDe = () => {
        // Desplaza al componente de BranchSelection
        const branchSelectionSection = document.getElementById('acercaDe');
        if (branchSelectionSection) {
            branchSelectionSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className="font-bold text-2xl relative z-10 text-white py-8 ">
            <div className="flex ml-8 lg:ml-0 lg:justify-center lg:border-t lg:border-b lg:border-gray-300 lg:border-opacity-50 pt-4 pb-4">
                <nav className="flex space-x-6 md:space-x-8">
                    <button
                        className="lg:hidden flex justify-center w-8 h-8"
                        onClick={handleToggle}
                    >
                        {isOpen ? (
                            <motion.div
                                className="text-3xl"
                                initial={{ rotate: 0 }}
                                animate={{ rotate: 180 }}
                                transition={{ duration: 0.3 }}
                            >
                                <FiX />
                            </motion.div>
                        ) : (
                            <motion.div
                                className="text-3xl"
                                initial={{ rotate: 0 }}
                                animate={{ rotate: -180 }}
                                transition={{ duration: 0.3 }}
                            >
                                <FiMenu />
                            </motion.div>
                        )}
                    </button>

                    <ul
                        className={`${isOpen ? 'block' : 'hidden'
                            } lg:flex lg:space-x-6 md:space-x-8`}
                    >
                        <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                        >
                            <a href="#">Inicio</a>
                        </motion.li>

                        <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300 cursor-pointer"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                        >
                            <a onClick={handleChangeBranch}>Ubicaciones</a>
                        </motion.li>

                        {/* <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                            >
                            <a href="#">Blog</a>
                            </motion.li> */}
                        <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                        >
                            <a className='cursor-pointer' onClick={goToContacto}>Contacto</a>
                        </motion.li>
                        <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                        >
                            <a className='cursor-pointer' onClick={goToContacto}>Pedidos</a>
                        </motion.li>
                        <motion.li
                            className="hover:text-yellow-300 transition-colors duration-300"
                            whileHover={{ scale: 1.1, color: '#F59E0B' }}
                        >
                            <a className='cursor-pointer' onClick={goToAcercaDe}>Acerca de</a>
                        </motion.li>
                    </ul>

                </nav>
            </div>
        </header>
    );
};

export default Header;