import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useBranch } from '../context/BranchContext.js'; // Ajusta la ruta según tu estructura

const BranchSelectionPopup = ({ isOpen, onClose, onContinue }) => {
  const { selectedBranch } = useBranch();

  const handleChangeBranch = () => {
    // Desplaza al componente de BranchSelection
    const branchSelectionSection = document.getElementById('branch-selection');
    if (branchSelectionSection) {
      branchSelectionSection.scrollIntoView({ behavior: 'smooth' });
      onClose(); // Cierra el popup después de desplazar
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="absolute inset-0 bg-black opacity-60" />
          <motion.div
            className="bg-white p-6 md:p-8 rounded-lg shadow-lg z-10 max-w-md w-full text-center relative"
            initial={{ y: '-30px' }}
            animate={{ y: '0' }}
            exit={{ y: '-30px' }}
            transition={{ duration: 0.3 }}
          >
            <button
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              <FaTimes size={20} />
            </button>
            <h2 className="text-xl md:text-2xl font-semibold mb-4">Sucursal Seleccionada</h2>
            <p className="text-base md:text-lg text-gray-700 mb-6">
              Has seleccionado la sucursal: <span className="font-bold">{selectedBranch}</span>
            </p>
            <div className="flex flex-col gap-4 items-center">
              <button
                className="bg-yellow-500 text-white px-6 py-2 rounded-lg hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                onClick={onContinue}
              >
                Continuar
              </button>
              <button
                className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
                onClick={handleChangeBranch}
              >
                Cambiar Sucursal
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BranchSelectionPopup;
