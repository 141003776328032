import './App.css';
import { BranchProvider } from './context/BranchContext.js';
import Hero from "./components/hero";
import Gallery from "./components/gallery";
import BranchSelection from "./components/branch";
import ContactInfo from './components/footer.js';
import PizzaRotationSection from './components/pizzaRotationSection.js';

function App() {
  return (
    <div className="App">
      <BranchProvider>

        <Hero></Hero>
        <Gallery></Gallery>
        <BranchSelection></BranchSelection>
        <PizzaRotationSection></PizzaRotationSection>
        <ContactInfo></ContactInfo>
      </BranchProvider>

    </div>
  );
}

export default App;
