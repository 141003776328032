import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaWhatsapp, FaPhone, FaInstagram, FaFacebook, } from 'react-icons/fa';
import { CgMail } from "react-icons/cg";

import { useBranch } from '../context/BranchContext.js';

const ContactInfo = () => {
  const { selectedBranch } = useBranch();
  const [branchInfo, setBranchInfo] = useState({});


  useEffect(() => {
    // Cargar los datos del archivo JSON
    fetch('/contactInfo.json')
      .then((response) => response.json())
      .then((data) => {
        setBranchInfo(data[selectedBranch] || {});
      })
      .catch((error) => {
        console.error('Error fetching contact info:', error);
      });
  }, [selectedBranch]);

  return (
    <footer id='footer' className="bg-gray-900 text-white py-6 px-4" style={{ backgroundColor: '#1a1a1a' }}>
      <div className="container mx-auto">
        <motion.h2
          className="text-2xl md:text-3xl font-bold mb-12 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Contacta con Nosotros
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Sucursal Seleccionada */}
          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">Sucursal Seleccionada</h3>
            <p className="text-lg md:text-xl">{selectedBranch}</p>
          </motion.div>

          {/* Teléfonos */}
          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">Teléfonos</h3>
            <ul className="list-disc pl-5 space-y-2">
              {branchInfo.phoneNumbers && branchInfo.phoneNumbers.map((number, index) => (
                <motion.li
                  key={index}
                  className="flex items-center"
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <FaPhone className="text-yellow-400 mr-2" />
                  {/* Enlace para hacer clic y llamar */}
                  <a href={`tel:${number}`} className="text-yellow-400  hover:underline">
                    {number}
                  </a>
                </motion.li>
              ))}
            </ul>
          </motion.div>


          {/* WhatsApp */}
          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">WhatsApp</h3>
            <a
              href={`https://wa.me/${branchInfo.phoneNumbers ? branchInfo.phoneNumbers[0] : ''}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-yellow-400 hover:underline"
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <FaWhatsapp className="mr-2" />
              </motion.div>
              Enviar mensaje/Realizar pedidos
            </a>
          </motion.div>

          {/* Instagram */}
          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">Instagram</h3>
            <a
              href={`https://www.instagram.com/${branchInfo.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-yellow-400 hover:underline"
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <FaInstagram className="mr-2" />
              </motion.div>
              @{branchInfo.instagram}
            </a>
          </motion.div>

          {/* Facebook */}
          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">Facebook</h3>
            <a
              href={`https://www.facebook.com/${branchInfo.facebook}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-yellow-400 hover:underline"
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <FaFacebook className="mr-2" />
              </motion.div>
              {branchInfo.facebook}
            </a>
          </motion.div>

          <motion.div
            className="flex flex-col items-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-2">Gmail</h3>
            <a
              href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=lapizzadatucuman@gmail.com
`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-yellow-400 hover:underline"
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <CgMail className="mr-2 text-xl" />
              </motion.div>
              lapizzadatucuman@gmail.com
            </a>
          </motion.div>
        </div>
      </div>
    </footer>
  );
};

export default ContactInfo;
